import React from "react"

import Layout from "../shared/Layout/layout"
import { DefaultRouteProps } from "../utils/types"

const NotFoundPage = (_props: DefaultRouteProps) => (
  <Layout>
    <div className="container text-center" style={{ padding: "25%" }}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
