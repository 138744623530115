import { Auth } from "aws-amplify"
import { Response, IActionOptionListItem } from "../../../types/responses"
import { ActionStatusEnum, ActionTypeEnum, IClientAction, FormInputRequirements } from "../../../types/index"
import { RequestBody } from "../../../types/requests"
import axios from "axios"
import { FormFields } from "../shared/FormInput/formInput.component"

type HeaderOptions = {
  Authorization: string
  "Content-Type": string
}

export type ActivityQueryParams = {
  page?: number | undefined
  type?: ActionTypeEnum | undefined
  status?: ActionStatusEnum | undefined
}

export type MakeDecisionParams = {
  actionId: string
} & RequestBody["approveOrReject"]

type ActionInput = {
  actionName: string
  actionInput: FormFields
}

class CSPortal {
  public async getHeaders(): Promise<HeaderOptions> {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()

    const headerOptions = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }

    return headerOptions
  }

  private getCSServiceFullURL(path: string): string {
    const baseUrl = process.env.BASE_CS_SERVICE_URL as string
    return `${baseUrl}${path}`
  }

  public async getCSActions(): Promise<IActionOptionListItem[]> {
    const endpointPath = this.getCSServiceFullURL("/actions")
    const headers = await this.getHeaders()

    const response = await axios.get(endpointPath, {
      headers,
    })

    const actions: IActionOptionListItem[] = response.data.actions
    return actions
  }

  public async getActivity({ type, status, page }: ActivityQueryParams): Promise<Response["actionsActivity"]> {
    const endpointPath = this.getCSServiceFullURL("/actions/activity")

    const queryParams: ActivityQueryParams = {
      ...(type && { type }),
      ...(status && { status }),
      ...(page && { page }),
    }

    const headers = await this.getHeaders()

    const response = await axios.get(endpointPath, {
      headers,
      params: queryParams,
    })

    const activity: Response["actionsActivity"] = await response.data

    return activity
  }

  public async makeDecisionOnAction({ status, adminNote, actionId }: MakeDecisionParams): Promise<IClientAction> {
    const endpointPath = this.getCSServiceFullURL(`/actions/${actionId}`)

    const actionDecisionParams = {
      status,
      adminNote,
    }

    const headers = await this.getHeaders()

    const response = await axios.patch(endpointPath, actionDecisionParams, { headers })

    const decidedAction: IClientAction = await response.data

    return decidedAction
  }

  public async getActionRequirements(actionType: ActionTypeEnum): Promise<FormInputRequirements> {
    const endpointPath = this.getCSServiceFullURL(`/actions/${actionType}`)
    const headers = await this.getHeaders()

    const response = await axios.get(endpointPath, { headers })

    const actionRequirements: FormInputRequirements = await response.data

    return actionRequirements
  }

  public async postActionInputs({ actionInput, actionName }: ActionInput): Promise<IClientAction> {
    const endpointPath = this.getCSServiceFullURL(`/actions/${actionName}`)
    const headers = await this.getHeaders()

    const response = await axios.post(endpointPath, actionInput, {
      headers,
    })

    const updatedAction: IClientAction = await response.data

    return updatedAction
  }
}

const CSPortalService = new CSPortal()

export default CSPortalService
