const identityPoolId = process.env.IDENTITY_POOL_ID as string
const region = process.env.REGION as string
const userPoolId = process.env.USER_POOL_ID as string
const userPoolWebClientId = process.env.USER_POOL_WEBCLIENT_ID as string
const domain = process.env.DOMAIN as string
const redirectSignIn = process.env.REDIRECT_SIGN_IN as string
const redirectSignOut = process.env.REDIRECT_SIGN_OUT as string

export const authConfig = {
  identityPoolId,
  region,
  userPoolId,
  userPoolWebClientId,
  oauth: {
    domain,
    scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn,
    redirectSignOut,
    responseType: "code",
  },
}

const authTheme = {
  button: {
    backgroundColor: "#007BCB",
  },
}

export const authenticatorOptions = {
  theme: authTheme,
}
