import React, { useEffect, useState } from "react"
import { Auth } from "aws-amplify"
import { Card } from "@blueprintjs/core"

type ProfileData = {
  email: string
  picture: string
  name: string
  groups: string
}

const SidebarProfile = () => {
  const initialState: ProfileData = {
    email: "",
    picture: "",
    name: "",
    groups: "",
  }

  const [profileData, setProfileData] = useState(initialState)

  const styles = {
    card: {
      width: "200px",
      height: "auto",
      margin: "0 auto",
      marginBottom: "2em",
    },
    image: {
      borderRadius: "500px",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
      padding: "15px",
    },
    textContainer: {
      marginTop: "-25px",
      height: "70px",
    },
  }

  const getUserInformation = async () => {
    const auth = await Auth.currentAuthenticatedUser()

    const newProfile: ProfileData = {
      email: auth.signInUserSession.idToken.payload.email,
      name: auth.signInUserSession.idToken.payload.name,
      picture: auth.signInUserSession.idToken.payload.picture,
      groups: auth.signInUserSession.idToken.payload["cognito:groups"].join(" | "),
    }

    setProfileData(newProfile)
  }

  // Closes the promise
  useEffect(() => {
    getUserInformation()
      .then()
      .catch((e) => {
        console.log(`An error has occured ${e}`)
      })
  }, [])

  return (
    <Card elevation={2} style={styles.card}>
      <img style={styles.image} src={profileData.picture} />
      <div style={styles.textContainer}>
        <h6 style={{ textAlign: "center", fontFamily: "Quicksand" }}>
          Hello, {profileData.name}
          <br />
          <br />
          <span style={{ color: "#C6C6C6", fontSize: "12px" }}>{profileData.groups} </span>
        </h6>
      </div>
    </Card>
  )
}

export default SidebarProfile
