import React, { FunctionComponent, useState, useEffect } from "react"
import { RouteProps } from "../../utils/types"
import { navigate } from "gatsby"
import { Auth } from "aws-amplify"

const RedirectAuthPage: FunctionComponent<RouteProps> = () => {
  const [hasAuth, setHasAuth] = useState(false)
  const checkAuth = async (): Promise<any> => {
    await Auth.currentAuthenticatedUser()
      .then(() => setHasAuth(true))
      .catch(() => console.log("NOT AUTHED"))
  }

  useEffect(() => {
    checkAuth().then(() => {
      if (hasAuth) {
        navigate("/app/users/search")
      } else {
        navigate("/app")
      }
    })
  }, [])
  return <></>
}
export default RedirectAuthPage
