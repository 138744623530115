import React from "react"
import { Classes, ITreeNode, Tree } from "@blueprintjs/core"
import { Link } from "gatsby"
import CSPortalService from "../../data-sources/csRestApi"
import * as apiTypes from "../../../../types/index"
import RedashIcon from "../../images/redash.svg"

import "../../styles/global.css"

type DropdownState = {
  dropDownItems: ITreeNode[]
  actions: apiTypes.IActionOptionListItem[]
}

type ActionSidebarDropdownProps = {}

export class SidebarDropdown extends React.Component<ActionSidebarDropdownProps, DropdownState> {
  constructor(props: any) {
    super(props)
    this.state = {
      dropDownItems: [],
      actions: [],
    }
  }

  private randomId(): string {
    return Math.random()
      .toString(36)
      .substring(7)
  }

  public async componentDidMount() {
    const actions: apiTypes.IActionOptionListItem[] = await CSPortalService.getCSActions()

    const turnActionsToDropdownItems = (actionItems: apiTypes.IActionOptionListItem[]) => {
      const SUBTITLE_CLASSES = "sublabel-font"
      const LABEL_CLASSES = "label-font title-text action-bar"

      // tslint:disable-next-line: no-shadowed-variable
      const dropDownItems: ITreeNode[] = [
        {
          id: this.randomId(),
          hasCaret: false,
          icon: "people",
          label: (
            <Link className={LABEL_CLASSES} to="/app/users/search">
              Users
            </Link>
          ),
          className: LABEL_CLASSES,
          childNodes: [],
        },
        {
          id: this.randomId(),
          hasCaret: false,
          icon: "layers",
          label: (
            <Link className={LABEL_CLASSES} to="/app/activity">
              Activity
            </Link>
          ),
          className: LABEL_CLASSES,
          childNodes: [],
        },
        {
          id: this.randomId(),
          hasCaret: true,
          icon: "layout-circle",
          label: "Actions",
          className: LABEL_CLASSES,
          isExpanded: true,
          childNodes: [],
        },
        {
          id: this.randomId(),
          hasCaret: true,
          icon: "manual",
          label: "Resources",
          className: LABEL_CLASSES,
          isExpanded: false,
          childNodes: [
            {
              id: 2,
              label: (
                <Link className="sublabel-font" to="/app/resources/redash-transaction-search">
                  TRN Search <img height="22px" width="auto" src={RedashIcon} />
                </Link>
              ),
              className: SUBTITLE_CLASSES,
            },
          ],
        },
      ]

      actionItems.forEach((item) => {
        const dropDownItem = {
          id: this.randomId(),
          label: (
            <Link className="sublabel-font" to={`/app/actions/${item.type}`}>
              {item.label}
            </Link>
          ),
          className: SUBTITLE_CLASSES,
        }

        if (dropDownItems[2].childNodes) {
          dropDownItems[2].childNodes.push(dropDownItem)
        }
      })
      return dropDownItems
    }

    const newDropDownItems = turnActionsToDropdownItems(actions)
    this.setState({
      actions,
      dropDownItems: newDropDownItems,
    })
  }

  public render() {
    return (
      <Tree
        contents={this.state.dropDownItems}
        onNodeClick={this.handleNodeClick}
        onNodeCollapse={this.handleNodeCollapse}
        onNodeExpand={this.handleNodeExpand}
        className={Classes.ELEVATION_0}
      />
    )
  }

  private handleNodeClick = (nodeData: ITreeNode, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
    const originallySelected = nodeData.isSelected
    if (!e.shiftKey) {
      this.forEachNode(this.state.dropDownItems, (n) => (n.isSelected = false))
    }
    nodeData.isSelected = originallySelected == null ? true : !originallySelected
    this.setState(this.state)
  }

  private handleNodeCollapse = (nodeData: ITreeNode) => {
    nodeData.isExpanded = false
    this.setState(this.state)
  }

  private handleNodeExpand = (nodeData: ITreeNode) => {
    nodeData.isExpanded = true
    this.setState(this.state)
  }

  // Since this uses recursion to check through the nav tree, nodes can be undefined / null
  private forEachNode(nodes: ITreeNode[] | undefined, callback: (node: ITreeNode) => void) {
    if (nodes == null) {
      return
    }

    for (const node of nodes) {
      callback(node)
      this.forEachNode(node.childNodes, callback)
    }
  }
}

export default SidebarDropdown
