/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FunctionComponent } from "react"

import "./layout.css"
import Sidebar from "react-sidebar"
import SidebarContent from "../SidebarContent/sidebarContent"

type LayoutProps = {
  navType?: "HOME"
}

const Layout: FunctionComponent<LayoutProps> = ({ children, navType }) => {
  // example of gatsby data query for myself
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  return (
    <Sidebar
      sidebar={<SidebarContent navType={navType} />}
      docked={true}
      sidebarClassName="sidebar-wrapper"
      contentClassName="content"
      touch={false}
    >
      {children}
    </Sidebar>
  )
}

export default Layout
