import React, { FunctionComponent } from "react"
import SidebarDropdown from "../SidebarDropdown/sidebarDropdown"
import SidebarProfile from "../SidebarProfile/sidebarProfile.component"
import Logo from "../../images/logo.svg"
import "./sidebar-content.css"
import { Link, navigate } from "gatsby"
import { Auth } from "aws-amplify"

type Props = {
  navType?: "HOME"
}

const SidebarContent: FunctionComponent<Props> = ({ navType }: Props) => {
  const styles = {
    logoutButton: { fontFamily: "Quicksand", color: "#FFF" },
    container: { overflow: "hidden" },
  }

  const logout = () => {
    Auth.signOut().then(() => navigate("/app"))
  }

  if (navType === "HOME") {
    return (
      <Link to="/">
        <div>
          <img src={Logo} width="300px" className="home-logo" />

          <h2 className="home-text">CS Portal</h2>
        </div>
      </Link>
    )
  } else {
    return (
      <div style={styles.container}>
        <Link to="/app/">
          <img src={Logo} className="logo" />
        </Link>

        <SidebarProfile />
        <SidebarDropdown />
        <a onClick={logout}>
          <h1 style={styles.logoutButton} className="text-center">
            Logout
          </h1>
        </a>
      </div>
    )
  }
}

export default SidebarContent
